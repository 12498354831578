import accessibleAutocomplete from 'accessible-autocomplete';

function getOriginalMakeSelect() {
  return document.querySelector('#make');
}

function getEnterMakeGroup() {
  return document.querySelector('#enter-make-group');
}

function getEnterMakeText() {
  return document.querySelector('#enter-make');
}

function renameOriginalSelectElement() {
  document.querySelector('select').name = 'original-select';
}

function getDropdownSvg(config) {
  return `<svg class="${config.className}" style="top: 8px;" viewBox="0 0 512 512" ><path d="M256,298.3L256,298.3L256,298.3l174.2-167.2c4.3-4.2,11.4-4.1,15.8,0.2l30.6,29.9c4.4,4.3,4.5,11.3,0.2,15.5L264.1,380.9  c-2.2,2.2-5.2,3.2-8.1,3c-3,0.1-5.9-0.9-8.1-3L35.2,176.7c-4.3-4.2-4.2-11.2,0.2-15.5L66,131.3c4.4-4.3,11.5-4.4,15.8-0.2L256,298.3  z"/></svg>`;
}

function getAutocompleteName() {
  return `${document.querySelector('form').name}[make]`;
}

function toggleOther(value) {
  const makeGroup = getEnterMakeGroup();
  if (typeof value !== 'undefined') {
    getEnterMakeText().value = '';
    if (value === 'Other') {
      makeGroup.classList.remove('hidden');
    } else {
      makeGroup.classList.add('hidden');
    }
  }
}

export default function renderAutocomplete() {
  accessibleAutocomplete.enhanceSelectElement({
    autoselect: false,
    dropdownArrow: getDropdownSvg,
    name: getAutocompleteName(),
    onConfirm: toggleOther,
    placeholder: 'Select a make',
    selectElement: getOriginalMakeSelect(),
    showAllValues: true,
  });

  document.addEventListener('DOMContentLoaded', () => {
    const makeGroup = getEnterMakeGroup();
    if (getOriginalMakeSelect().value === 'Other') {
      makeGroup.classList.remove('hidden');
    } else {
      makeGroup.classList.add('hidden');
    }
    // ensure the original element is renamed or inputs will be doubly submitted
    renameOriginalSelectElement();
  });
}
